import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";

function Patenschaft() {
    document.documentElement.classList.remove("nav-open");
    return (
        <>
            <IndexNavbar transparentUntilScrolled={false} />
            <Container className="pt-5">
                <Row>
                    <Col className="ml-auto mr-auto text-center title" md="6">
                        <h2>Patenschaft</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="10">
                        <div className="article-content">
                            <h3>Mit einer Patenschaft bekommst du die Möglichkeit, dein Lieblingstier für ein Jahr zu begleiten. Bei exklusiven Events hast du die Chance, dein Tier noch näher kennenzulernen.</h3>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col className="ml-auto pt-5 pb-3">
                        <img className="img-fluid" alt="..." src={"/img/paul-pate.jpg"} />
                    </Col>
                </Row>
                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was beinhaltet eine Patenschaft?</h3>

                            <ul>
                                <li><p>Die Patenschaft ist vom 01. Januar bis 31. Dezember gültig.</p></li>
                                <li>
                                    <p>Du erhältst eine individuelle <b>Patenschaftsurkunde</b> und wirst (wenn du möchtest) namentlich auf der Homepage als Pate genannt.</p>
                                </li>

                                <li>
                                    <p>Damit dein Tier dich immer begleiten kann, bekommst du von uns einen <b>Ansteckbutton</b>, auf dem dein Tier abgebildet ist. Für Zuhause könnt ihr euch über einen <b>Kühlschrankmagneten</b> freuen.</p>
                                </li>
                                <li>
                                    <p>Ein besonderes Highlight ist unser <b>Meet & Greet</b>, bei dem du dein Patentier persönlich kennenlernen kannst. Gemeinsam mit einer Begleitperson genießt du <b>Glühwein</b> und Punsch in gemütlicher Atmosphäre.</p>
                                </li>
                                <li>
                                <p>Außerdem laden wir dich und deine Begleitperson zu einer <b>Alpakawanderung</b> ein. Im Anschluss lassen wir den Tag in Anwesenheit der Tiere bei einem <b>Aperol</b> entspannt ausklingen.</p>
                                </li>
                                <li>
                                <p>Zum Jahresabschluss treffen sich alle Paten zu einer gemeinsamen <b>Wanderung</b>.
                                    Beim gemütlichen Zusammensitzen in unserer Stube genießen wir gemeinsam <b>Kaffee und Kuchen</b>.</p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="article-content">
                            <h3>Wie kannst du Pate werden?</h3>
                        </div>
                        <p>Auf der <a href="/animals">Unsere Tiere</a> Seite bekommst du einen Überblick, für welche Tiere wir eine Patenschaft anbieten. Hast du dich für ein Tier entschieden, schreib uns eine Nachricht über das <a href="/contact">Kontaktformular</a> oder per E-Mail.</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="article-content">
                            <h3>Termine 2025
                            </h3>
                        </div>
                        <p>Für unsere Paten haben wir im Jahr 2025 die folgenden exklusiven Termine geplant:
                            </p>

                            <ul>
                                <li>08.02.2025 - 13:00 Glüh- & Greet auf der Alpakawiese</li>
                                <li>03.05.2025 - 17:30 Alpakerolwanderung</li>
                                <li>25.10.2025 - 13:00 Alpakawandern meets Kaffeeklatsch</li>
                            </ul>
                    </Col>
                </Row>

                <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <div className="article-content">
                            <h3>Was kostet eine Patenschaft?</h3>
                            <Col className="ml-auto mr-auto" md="12">
                                <Card className="card-pricing card-plain">
                                    <CardBody>
                                        <h6 className="card-category">Patenschaft für ein Kalenderjahr</h6>
                                        <CardTitle tag="h3">€ 199.00</CardTitle>
                                        <p className="card-description">

                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Patenschaft;
